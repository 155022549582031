.App {
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.logo-link {
  position: absolute;
  bottom: 1vh;
  z-index: 20000;
}

.youtube {
  right: 15vw;
}

@media screen and (min-width: 400px) {
  .logo-link {
    height: 5vh;
    right: 1vw;
  }

  .youtube {
    right: 3vw;
  }
}

@media screen and (max-width: 391px) {
}

@media screen and (max-width: 440px) {
  .logo-link {
    right: 1vw;
    height: 3vh;
    z-index: 20000;
  }

  .youtube {
    right: 5vw;
  }

  .show-container {
    width: 100vw !important;
  }

  .show {
    margin-top: -20px !important;
  }

  .active {
    width: auto;
    height: auto;
  }
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

a {
  text-decoration: none;
  background-color: white;
}

.show-container {
  height: 50vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 50vw;
  font-size: 16px;
}

.show {
  position: absolute;
}

.not-active {
  display: none;
}

@media screen and (min-width: 1000px) {
  .active {
    width: 110vw;
    height: auto;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
